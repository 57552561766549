import React from 'react';
import './ErrorCard.css'; // Import CSS for styling

function ErrorCard({onCloseClick}) {
  return (
    <div className="alert" id='alert'>
      <span className="closebtn" onClick={onCloseClick}>&times;</span>
        Something went wrong, please try again.
    </div>
  );
}

export default ErrorCard
