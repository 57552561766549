import React, { useState, useEffect } from 'react';
import './ProtoGridBackground.css'; // Import CSS for styling
import { getAuth, getRedirectResult } from 'firebase/auth';
import {  signInWithRedirect, GoogleAuthProvider } from "firebase/auth";
import API from './preapi';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import LoaderComponent from './LoaderComponent';
import ErrorCard from './ErrorCard';

const provider = new GoogleAuthProvider();

function ProtoGridBackground({
  showAppNameAndLogo = true,
}) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, showError] = useState(false)

  const handleCloseError = () => {
    showError(false)
  }

  useEffect(() => {
    setLoading(true)
    const auth = getAuth();
    getRedirectResult(auth)
      .then((result) => {
        const user = result.user;

        user.getIdToken()
        .then((token) => {
        API.post('users/google', { token: token, fcmToken:'' }).then((response) => {
          let user = response.data.user
          let token = response.data.token
          Cookies.set('token', token, { secure: true, expires: 7});
          localStorage.setItem('userId', user.id)
          localStorage.setItem('firstName', user.firstName)
          localStorage.setItem('lastName', user.lastName)
          localStorage.setItem('email', user.email)
          localStorage.setItem('gender', user.gender)
          let hasTrainingCredits = response.data.user.hasTrainingCredits
          let hasUploadedPhotos = response.data.user.hasUploadedPhotos

          if(user.gender == null) {
            navigate('/gender');
          } else if(hasUploadedPhotos) {
            navigate('/portfolio');
          } else if(hasTrainingCredits){
            navigate('/guide');
          } else {
            navigate('/payment');
          }
        }).catch((error) => {
        });
    }).catch((error) => {
    })
  }).catch((error) => {
  }).finally(() => {
    setLoading(false)
  });
  }, [])
  const handleGoogleSignIn = async () => {
    setLoading(true);
    const auth = getAuth();
    signInWithRedirect(auth, provider);
  };
  return (
    <div className="proto-grid-background">
      {error && <ErrorCard onCloseClick={handleCloseError}/>}
      {loading && <LoaderComponent/>}
      <div className="content-column">
        {showAppNameAndLogo && (
          <div className="column">
            <img src={`./ic_round_logo.svg`} alt="" className="logo" />
            <h1 className="title">Shuttr.ai</h1>
          </div>
        )}
        {<>
          <h2 className="subtitle" textAlign="center">
            Create the best version of yourself!
          </h2>
          <button
            className="google-button"
            // disabled={loading}
             onClick={handleGoogleSignIn}
          >
            {/* {loading ? (
              <div className="spinner">Loading...</div>
            ) : ( */}
              <img src={`./google.png`} alt="Sign in with Google" />
            {/* )} */}
          </button>
          <p>
            By signing in with Google, you agree to our{' '}
            <a href="https://www.shuttr.ai/blank-page" target="_blank" rel="noopener noreferrer">
              Terms of use
            </a>
            ,{' '}
            <a href="https://www.shuttr.ai/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            , and{' '}
            <a href="https://www.shuttr.ai/returns-refunds" target="_blank" rel="noopener noreferrer">
              Refund Policy
            </a>
            .
          </p>
        </>}
      </div>
      <div className="row">
        <div className="column" style={{ flex: 1 }}>
          <ul className="lazy-column">
            {Array.from({ length: 10 }, (_, index) => (
              <li key={index}>
                <img
                  src={`./ph_${index % 10 + 5}.png`} // Adjust image paths
                  alt=""
                  className="rounded-image"
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="column" style={{ flex: 1 }}>
          <ul className="lazy-column">
            {Array.from({ length: 10 }, (_, index) => (
              <li key={index}>
                <img
                  src={`./ph_${index % 10 + 1}.png`} // Adjust image paths
                  alt=""
                  className="rounded-image"
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="column" style={{ flex: 1 }}>
          <ul className="lazy-column">
            {Array.from({ length: 10 }, (_, index) => (
              <li key={index}>
                <img
                  src={`./ph_${index % 10 + 9}.png`} // Adjust image paths
                  alt=""
                  className="rounded-image"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="gradient-box"></div>
      
    </div>
  );
}

export default ProtoGridBackground;