import React from 'react';
function GuideImage({imageRes, suggested}) {

  return (
    <div
      style={{
        width: '135px',
        height: '135px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <img
        src={imageRes}
        alt=""
        style={{
          width: '120px',
          height: '120px',
          borderRadius: '20px',
        }}
      />
      {suggested && (
        <img
          src="./ic_check.svg"
          alt="check"
          style={{
            position: 'relative',
            width: '20px',
            height: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '8px'
          }}
        />
      )}
      {!suggested && (
        <img
          src="./cancel_white.svg"
          alt="cancel"
          style={{
            position: 'relative',
            width: '20px',
            height: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '8px'
          }}
        />
      )}
    </div>
  );
}


export default GuideImage;
