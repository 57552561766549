import React, { useState, useEffect } from 'react';
import './SelectGender.css'
import API from '../api'
import { useNavigate } from 'react-router-dom';
import LoaderComponent from '../LoaderComponent'
import ErrorCard from '../ErrorCard'

const SelectGenderScreen = ({ goToPaymentScreen, onError, onBackClick }) => {
  const [gender, setGender] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, showError] = useState(false);
  const userId = localStorage.getItem('userId')
  const navigate = useNavigate();

  const handleGenderSelect = (selectedGender) => {
    setGender(selectedGender);
  };

  const handleCloseError = () => {
    showError(false)
  }

  const handleContinueClick = async () => {
    setLoading(true);
    API.post('users/gender', 
        {
            userId: userId,
            gender: gender
        }
    ).then((response) => {
      navigate('/payment');
    }).catch((error) => {
      showError(true)
      console.log('I was triggered during ' + error)
    }).finally(() => {
      setLoading(false)
    })
  };

  return (
    <div className="container">
      {loading && <LoaderComponent/>}
      {error && <ErrorCard onCloseClick={handleCloseError}/>}
      <header className="header">
        <h2 className="header-title">Select Gender</h2>
      </header>
      <ul className="gender-list">
        <li
          className={`gender-option ${gender === 'MALE' ? 'active' : ''}`}
          onClick={() => handleGenderSelect('MALE')}
        >
          Male
          {gender === 'MALE' && <span className="selected-icon">✅</span>}
        </li>
        <li
          className={`gender-option ${gender === 'FEMALE' ? 'active' : ''}`}
          onClick={() => handleGenderSelect('FEMALE')}
        >
        Female
          {gender === 'FEMALE' && <span className="selected-icon">✅</span>}
        </li>
      </ul>
      <button className="continue-button" disabled={gender === null} onClick={handleContinueClick}>
        Continue
      </button>
    </div>
  );
};

// Define your own Spinner and ErrorMessage components

export default SelectGenderScreen;