import { React, useState, useEffect, useRef  } from 'react';
import ImageAttachment from './ImageAttachment';
import PriorityQueue from 'js-priority-queue';
import State from './State';
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import './ImageButton.css'; // Import your CSS for styles
import LoaderComponent from './LoaderComponent';
import ErrorCard from './ErrorCard'
import API from './api'
import app from './Firebase'
import { useNavigate } from 'react-router-dom';


const storage = getStorage(app, "gs://proto-d1b95.appspot.com");
const queue = new PriorityQueue()
const imageMap = new Map()

for (let i = 0; i < 10; i++) {
     queue.queue(i)
}

function ImageButton() {
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId')
    const [showLoader, setShowLoader] = useState(false);
    const [error, showError] = useState(false);
    const [images, setImages] = useState([]);
    const fileInput = useRef(null);
    
    
    const handleCloseError = () => {
      showError(false)
    }

    const handleContinueClick = () => {
      setShowLoader(true)
      API.get('training/train', { params: {
        userId: userId
      }}).then(() => {
        navigate('/portfolio')
      }).catch((error) => {
        showError(true)
      }).finally(() => {
        setShowLoader(false)
      });
    }
    const onRemoveImage = (imageName) => {
      console.log(imageName)
        const nameSplit = imageName.split('.')
        const key = parseInt(nameSplit[0])
        queue.queue(key)
        imageMap.delete(key)
        setImages(images.filter(item => item.imageName !== imageName))
    }

    const handleImageUpload = (event) => {
      const selectedFiles = event.target.files; // Get all selected files

      for (let i = 0; i < selectedFiles.length; i++) {
        const selectedFile = selectedFiles[i];
        const imageUri = URL.createObjectURL(selectedFile)
        const imageName = `${queue.dequeue()}.jpg`; 
        console.log(imageName);
        const attachment = new ImageAttachment(imageUri, imageName, 0, State.LOADING)
        imageMap[imageName] = attachment
        const storageRef = ref(storage, `training_images/${userId}/${imageName}`);
        images.push(attachment)
        const uploadTask = uploadBytesResumable(storageRef, selectedFile);
        
      uploadTask
      .on(
      'state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            let imageAttachment = imageMap[snapshot.ref.name]
            imageAttachment.progress = Math.floor(progress)
            if(progress == 100) {
              imageAttachment.state = State.SUCCESS
            }
            const updatedImages = images.map(img => {
              if (img.imageName === snapshot.ref.name) {
                return new ImageAttachment(imageAttachment.uri, snapshot.ref.name, imageAttachment.progress, imageAttachment.state);
              } else {
                return img;
              }
            });
      
            setImages(updatedImages);
            imageMap[snapshot.ref.name] = imageAttachment
        }, 
        (error) => {}, 
        () => {}
      );
        console.log(images.length)
      }
    };


  return (
    <div style={{ flexDirection: 'column', 
    backgroundColor: 'black'}}>
      {showLoader && <LoaderComponent/>}
      {error && <ErrorCard onCloseClick={handleCloseError}/>}
    <div className="image-button-container">
      <label
        className="image-button"
      >
        <img src="./round_circle.svg" alt="Add image" />
        <input
          type="file"
          accept="image/*"
          onChange={(event) => handleImageUpload(event)}
          style={{ display: 'none' }}
          multiple
        />
      </label>
    </div>
    <div className="image-grid">
        {images.map((imageAttachment, index) => (
          <div className="image-item" key={index}>
            <img
            src={imageAttachment.uri}
            alt="Uploaded image"
            style={imageAttachment.progress != 100 ? { opacity: 0 } : { opacity: 1 }}
            />
            {imageAttachment.progress != 100 && <div className="loader" />} {/* Show loader while loading */}
            <div className="progress-text" style={imageAttachment.progress == 100 ? { opacity: 0 } : { opacity: 1 }}>
              {imageAttachment.progress}%
            </div>
            {imageAttachment.progress === 100 && <button className="close-icon" onClick={() => onRemoveImage(imageAttachment.imageName)}>
            &times;
            </button>}
              
          </div>
        ))}
      </div>
    
      {(images.every(img => img.progress === 100) && images.length === 10) && ( <button className="primary-button"  
          style={{ position: 'relative', width:'100%', maxWidth: 'none', marginBottom: '24px' }} onClick={handleContinueClick}>Continue</button> )}
        
    </div>
  );
}

export default ImageButton;