class PhotoGuideModel {
    constructor(title, description, imageList) {
      this.title = title;
      this.description = description;
      this.imageList = imageList;
    }
  }
  

  export default PhotoGuideModel ;
  