
import React, { useEffect, useRef } from 'react';
import './TrainingLoader.css'

function TrainingLoader() {
  return (
    <div className="loader-container">      
    <p style={{ textAlign: 'center',color: 'white', margin: '0 16px' }}>Training has failed</p>
    <p style={{ textAlign: 'center',color: 'white',  margin: '8px 16px', fontSize: '14px', opacity: 0.8 }}>Please write to us at support@shuttr.ai</p>
    </div>
  );
}

export default TrainingLoader;