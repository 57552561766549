import React from 'react';
import './FullImage.css'

function FullImage({url, onClose}) {
    return (
        <div className="full-image-container">
          <img src={url} alt="Uploaded image" className="full-image" />
          <div className="controls">
            <button className="close-button" onClick={onClose}>
              ×
            </button>
           
          </div>
          <button className="download-button" onClick={() => handleDownload(url)}>Download</button>
          {/* <a href={url} download="image.png">Download</a> */}
        </div>
      );
}
function handleDownload(url) {
    const filename = 'image.png'; 
    const link = document.createElement('a');
    link.href = url
    link.download = filename;
    link.click();
  }
export default FullImage;