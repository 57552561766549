import React from 'react';
import GuideImage from './GuideImage'; // Import your custom LoadImage component
import PhotoGuideModel from './guide/PhotoGuideModel';
import ImageModel from './guide/ImageModel';
import { useNavigate } from 'react-router-dom';

const photoGuideModels = [
    new PhotoGuideModel(
        'Quality',
        'Avoid low quality blurry photos, it makes it harder for us to understand details specially the eyes, use sharp high quality photos.',
      [
        new ImageModel('./dosanddonts/doquality.png', true),
        new ImageModel('./dosanddonts/dontquality.png', false)
      ]
    ),
    new PhotoGuideModel(
        'Background',
        'The only person in the photo should be the one for whom you want to generate a beautiful portrait.',
        [
          new ImageModel('./dosanddonts/dobackground.png', true), // Adjust image path as needed
          new ImageModel('./dosanddonts/dontbackground.png', false) // Adjust image path as needed
        ]
      ),
    
      new PhotoGuideModel(
        'Lights',
        'Avoid photos taken in harsh sunlight, use soft light.',
        [
          new ImageModel('./dosanddonts/dolighting.png', true), // Adjust image path as needed
          new ImageModel('./dosanddonts/dontlighting.png', false) // Adjust image path as needed
        ]
      ),
    
      new PhotoGuideModel(
        'Distance from camera',
        'Add close-up shots as it will help us understand your face. Keeping the camera far away will create poor results.',
        [
          new ImageModel('./dosanddonts/docameradistance.png', true), // Adjust image path as needed
          new ImageModel('./dosanddonts/dontcameradistance.png', false) // Adjust image path as needed
        ]
      )
  ];

function DosAndDont() {

  const handleContinueClick = () => {
    navigate('/upload')
  }

  const navigate = useNavigate();
  return (
    <div style={{ 
        padding: '0 16px',
        backgroundColor: 'black',
        color: 'white', /* Assuming you want white text for readability */
        textAlign: 'left',
        overflow: 'hidden',
    }}> 
    <div style={{ marginBottom: '16px'}}>
          <div style={{ marginBottom: '4px' }}>
            <h2>Guide</h2>
          </div>
          <p style={{ color: '#ccc', fontSize: '12px' }}>You need to upload 10 images to generate professional quality photographs. This page will guide you on how to choose the best ones.</p>
        </div>
      {photoGuideModels.map((item) => (
        <div key={item.id} style={{ marginBottom: '16px' }}>
          <div style={{ marginBottom: '4px' }}>
            <h2>{item.title}</h2>
          </div>
          <p style={{ color: '#ccc', fontSize: '12px' }}>{item.description}</p>
          <div style={{ display: 'flex', gap: '8px' }}>
            {item.imageList.map((imageItem) => (
              <GuideImage imageRes={imageItem.imageRes} suggested={imageItem.suggested} />
            ))}
          </div>
        </div>
      ))}
      
      <button
            className="primary-button"
            style={{ position: 'relative', marginTop: '16px', marginBottom: '16px', width:'100%', maxWidth: 'none', textAlign: 'center'}} 
            onClick={handleContinueClick}
          >Continue</button>
    </div>
  );
}

export default DosAndDont;