import { initializeApp, getApp, getApps } from "firebase/app";


const config = {
  apiKey: "AIzaSyAvl-drnrxHFYKY1tFPqMQ5E-j0ClDzN0E",
  authDomain: "app.shuttr.ai",
  projectId: "proto-d1b95",
  storageBucket: "proto-d1b95.appspot.com",
  messagingSenderId: "49435472694",
  appId: "1:49435472694:web:e5ae1fb13d21364576b997",
  measurementId: "G-1NDXFW97V3"
};


export default !getApps().length ?  initializeApp(config) : getApp();
