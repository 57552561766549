import React from 'react';
import ImageButton from './ImageButton';

function Upload() {
   
    return (
      <div style={{ 
        overflow: 'scroll',
        position: 'fixed',
        left: '0',
        right: '0',
        height: '100%',
        width: '100%',
        padding: '16px 16px',
        backgroundColor: 'black',
        color: 'white', /* Assuming you want white text for readability */
        textAlign: 'left',
        }}> 
        <div style={{ marginBottom: '16px'}}>
            <div style={{ marginBottom: '4px' }}>
              <h2>Training</h2>
            </div>
            <p style={{ color: '#ccc', fontSize: '12px' }}>Upload 10 photos so that we can generate your first portrait. Right now, we only accept JPG format.</p>
          </div>
        <ImageButton/>
      </div>
    );
  }

export default Upload;
