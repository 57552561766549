import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ProtoGridBackground from './ProtoGridBackground';
import Upload from './Upload';
import Portfolio from './portfolio/Portfolio';
import Payment from './payment/Payment'
import SelectGenderScreen from './gender/SelectGender';
import DosAndDont from './DosAndDont';
import AxiosNavigation from './AxiosNavigation'

function App() {
  return (
    <HashRouter>
      <AxiosNavigation/>

      <Routes>
          <Route index path="/" element={<ProtoGridBackground />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/gender" element={<SelectGenderScreen />} />
          <Route path="/guide" element={<DosAndDont />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
