// AxiosNavigation.js
import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './api';
import Cookies from 'js-cookie';

export function useAxiosNavigation() {
  // Use useRef to prevent a re-render in the useEffect.
  // A ref, cannot be used as a useEffect dependency, hence,
  // your linters shouldn't complain about missing dependencies.
  const navRef = useRef(useNavigate());

  useEffect(() => {
    const intercetpor = api.interceptors.response.use(
      (response) => response,
      (error) => {
        switch (error?.response?.status) {
          case 403:
            navRef.current('/');
            break;
          default:
        }
        return Promise.reject(error);
      }
    );
    const requestInterceptors = api.interceptors.request.use(
      request => {
            const token = Cookies.get('token')
            if(token == undefined) {
              navRef.current('/');
              return request;
            }
            console.log(token)
            request.headers['Authorization'] = `Bearer ${Cookies.get('token')}`;
            return request;
        },
        error => {
          console.log("error")
            return Promise.reject(error);
        }
    );

    return () => {
        api.interceptors.response.eject(intercetpor);
        api.interceptors.request.eject(requestInterceptors);
    };
  }, []);
}

export default function AxiosNavigation() {
  useAxiosNavigation();
  return <></>;
}