
import React, { useEffect, useRef } from 'react';
import './TrainingLoader.css'
import lottie from "lottie-web";

function TrainingLoader() {
  const container = useRef(null); 

  useEffect(() => {
        const instance = lottie.loadAnimation({
          animationData: require("./portfolio/loading_dots.json"),
          autoplay: true,
          container: container.current,
          loop: true,
          renderer: "svg",
        })
        return () => instance.destroy();
  }, [])
  return (
    <div className="loader-container">
      
    <div ref={container} ></div>
    <p style={{ textAlign: 'center',color: 'white', margin: '0 16px' }}>Your Training has started</p>
    <p style={{ textAlign: 'center',color: 'white',  margin: '8px 16px', fontSize: '14px', opacity: 0.8 }}>This is a complex process and will take 30-40 mins to complete. You can safely close the browser and come back again.</p>
    </div>
  );
}

export default TrainingLoader;