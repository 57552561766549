
import React from 'react';
import './LoaderComponent.css'

function LoaderComponent() {
  return (
    <div className="load-container">
      {/* Loader content, e.g., spinner or animation */}
      <div className="load"></div>
    </div>
  );
}

export default LoaderComponent;