
// State enum (unchanged)
const TransactionStatus = Object.freeze({
        CREATED: 'CREATED',
        COMPLETED: 'COMPLETED',
        FAILED: 'FAILED',
        CANCELLED: 'CANCELLED',
        PROCESSING: 'PROCESSING',
        UNHANDLED: 'UNHANDLED'
  });

export default TransactionStatus;