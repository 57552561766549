import React, { useState, useEffect } from 'react';
import '../ProtoGridBackground.css'; // Import CSS for styling
import {  GoogleAuthProvider } from "firebase/auth";
import API from '../api';
import { useNavigate } from 'react-router-dom';
import LoaderComponent from '../LoaderComponent';
import ErrorCard from '../ErrorCard';
import TransactionStatus from './TransactionStatus'

const provider = new GoogleAuthProvider();

function ProtoGridBackground({
  showAppNameAndLogo = true,
}) {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [error, showError] = useState(false);
  const [amount, setAmount] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(TransactionStatus.CREATED);
    
  const userId = localStorage.getItem('userId')
  
  const handleCloseError = () => {
    showError(false)
  }

  const handleWatchDemoClick = async () => {
    window.location.href = "https://www.youtube.com/shorts/0msiFV94Jg8"
  }

  const handlePaymentClick = async () => {
    setShowLoader(true)
    API.post('payment/phonepe/pay/web',  {
      selectedPlan: "SMALL",
      deviceOS: "WEB",
      userId: userId
    }).then((response) => {
      let redirectUrl = response.data.data
      window.location.href = redirectUrl
    }).catch((error) => {
      showError(true)
    }).finally(() => {
      setShowLoader(false)
    });
  }
  useEffect(() => {
    setShowLoader(true)
    API.post('payment/phonepe/amount', {
      selectedPlan: "SMALL",
      deviceOS: "WEB",
      userId: userId
    }).then((response) => {
      let data = response.data.data
      setAmount(data)
    }).catch((error) => {
      showError(true)
    }).finally(() => {
      setShowLoader(false)
    });
  }, [])
  
  useEffect(() => {
    setShowLoader(true)
    API.get('users/transaction/status', {
      params: {
        userId: userId
      }
    }).then((response) => {
      let status = response.data.data
      console.log(status)
      console.log(TransactionStatus.COMPLETED)
      if(status == TransactionStatus.COMPLETED) {
        navigate("/guide")
      } else {
        setTransactionStatus(status)
      }
    }).catch((error) => {
      showError(true)
    }).finally(() => {
      setShowLoader(false)
    });
  }, [])

  return (  
    <div className="proto-grid-background">
      {showLoader && <LoaderComponent/>}
      {error && <ErrorCard onCloseClick={handleCloseError}/>}

      <div className="content-column">
        {showAppNameAndLogo && (
          <div className="column">
            <img src={`./ic_round_logo.svg`} alt="" className="logo" />
            <h1 className="title">Shuttr.ai</h1>
          </div>
        )}
        {<>
          <h2 className="subtitle" textAlign="center">
            {(transactionStatus == TransactionStatus.FAILED || transactionStatus == TransactionStatus.CANCELLED || transactionStatus == TransactionStatus.UNHANDLED) &&
             ('Your transaction failed, please try again')}
             {(transactionStatus == TransactionStatus.PROCESSING) &&
             ('Your transaction is in process, please refresh this page to get the latest status')}
             {(transactionStatus == TransactionStatus.CREATED) &&
             ('AI generation needs expensive resources, please make the payment to proceed')}
          </h2>
          {(transactionStatus != TransactionStatus.PROCESSING) &&
          (<button

            style={{marginBottom : 16}}
            className="google-button"
            // disabled={loading}
             onClick={handleWatchDemoClick}
          >
           Watch demo 
          </button>)}
          {(transactionStatus != TransactionStatus.PROCESSING) &&
          (<button
            className="google-button"
            // disabled={loading}
             onClick={handlePaymentClick}
          >
          ₹{amount}
          </button>)}
        </>}
      </div>
      <div className="row">
        <div className="column" style={{ flex: 1 }}>
          <ul className="lazy-column">
            {Array.from({ length: 10 }, (_, index) => (
              <li key={index}>
                <img
                  src={`./ph_${index % 10 + 5}.png`} // Adjust image paths
                  alt=""
                  className="rounded-image"
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="column" style={{ flex: 1 }}>
          <ul className="lazy-column">
            {Array.from({ length: 10 }, (_, index) => (
              <li key={index}>
                <img
                  src={`./ph_${index % 10 + 1}.png`} // Adjust image paths
                  alt=""
                  className="rounded-image"
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="column" style={{ flex: 1 }}>
          <ul className="lazy-column">
            {Array.from({ length: 10 }, (_, index) => (
              <li key={index}>
                <img
                  src={`./ph_${index % 10 + 9}.png`} // Adjust image paths
                  alt=""
                  className="rounded-image"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="gradient-box"></div>
      
    </div>
  );
}

export default ProtoGridBackground;