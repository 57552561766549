import React, { useState, useEffect } from 'react';
import '../ImageButton.css'
import API from '../api'
import TrainingLoader from '../TrainingLoader'
import TrainingFailed from '../TrainingFailed'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import app from '../Firebase'
import ImageInfoDto from './ImageInfoDto'
import State from '../State';
import LoaderComponent from '../LoaderComponent';
import ErrorCard from '../ErrorCard';
import FullImage from './FullImage';
import { useNavigate } from 'react-router-dom';

const storage = getStorage(app, "gs://proto-d1b95.appspot.com");

function Portfolio() {
    const [images, setImages] = useState([])
    const [showFullScreen, setShowFullScreen] = useState(false)
    const [fullScreenUrl, setfullScreenUrl] = useState(null)
    const [jobType, setJobType] = useState(null)
    const [jobStatus, setJobStatus] = useState(null)    
    const userId = localStorage.getItem('userId')
    const [error, showError] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        for (let i = 0; i < 20; i++) {
          try {
            getDownloadURL(ref(storage, `result/${userId}/${i}.png`)).then((url) => {
              let decodedUrl = decodeURIComponent(url);
              let filename = decodedUrl.substring(decodedUrl.lastIndexOf('/') + 1).split("?")[0];
              let dto = new ImageInfoDto(url, `${i}.png`, State.SUCCESS)
              setImages(prevNames => [...prevNames, dto])
            }).catch((error) => {
              console.log(error)
            });
          } catch (error) {
            console.log(error)
          }
        }
    }, []);

    
  const handleCloseError = () => {
    showError(false)
  }

    const handleContinueClick = () => {
      let subject = "Support - Need help with Images"
      let message = "Hi, \n could you please help me with my generated images?"
      window.location.href = `mailto:support@shuttr.ai?subject=${subject}&body=${message}`;
    }
    
    const handleSaveZip = () => {
      setLoading(true)
      API.get('users/zipPortfolio', {
        params: {
          userId: userId
        }
      }).then((response) => {
        alert('A zip file containing all your images will be mailed to you at your login email. Kindly check your email after 12-13 minutes.')
      }).catch((error) => {
        showError(true)
      }).finally(() => {
        setLoading(false)
      })
    }
    
    const handleStartAgain = () => {
      if(window.confirm('All your images will be DELETED. Please make sure you SAVE them before starting again. You will also have to make the PAYMENT again.')) {
        setLoading(true)
        API.get('users/reset', {
          params: {
            userId: userId
          }
        }).then((response) => {
          navigate('/payment')
        }).catch((error) => {
          showError(true)
        }).finally(() => {
          setLoading(false)
        })
      } else {
        
      }
      
    }

    const handleImageClick = (url) => {
        setShowFullScreen(true)
        setfullScreenUrl(url)
    }

    useEffect(() => {
        API.get('training/status',{ params: {
          userId: userId
        }}).then((response) => {
          let responseJobType = response.data.data.jobType
          let responseJobStatus = response.data.data.jobStatus
          setJobType(`${responseJobType}`)
          setJobStatus(`${responseJobStatus}`)
        }).catch((error) => {
          const errorCode = error.code;
          console.log('I was triggered during ' + errorCode)
        });
    }, [])
    
    return (
        <div>
          {loading && <LoaderComponent/>}
          {error && <ErrorCard onCloseClick={handleCloseError}/>}
          {showFullScreen && <FullImage url={fullScreenUrl} onClose={() => setShowFullScreen(false)}/>}
          {(jobStatus == "FAILED") && <TrainingFailed/>}
          {(jobStatus == "COMPLETED" && images.length != 20) && <LoaderComponent/>}
          {(jobStatus == "IN_QUEUE" || jobStatus == "IN_PROGRESS") && <TrainingLoader/>}
          {(jobStatus == 'COMPLETED' && jobType == 'INFERENCE') && (<div style={{ 
            height: '100%',
            padding: '16px 16px',
            backgroundColor: 'black',
            color: 'white', /* Assuming you want white text for readability */
            textAlign: 'left',
            }}> 
            
        <div style={{ marginBottom: '16px'}}>
            <div style={{ marginBottom: '4px' }}>
              <h2>Portfolio</h2>
            </div>
            <p style={{ color: '#ccc', fontSize: '12px' }}>Your Portfolio is ready.</p>
        </div>
        
        <div className="image-grid">
          {images.map((imageInfoDto, index) => (
            <div className="image-item" key={index}>
              {imageInfoDto.state == State.SUCCESS && <img src={imageInfoDto.url} onClick={() => handleImageClick(imageInfoDto.url)} />}
            </div>
          ))}
        </div>
        <p
          style={{ position: 'relative', width:'100%', maxWidth: 'none', textAlign: 'center'}} onClick={handleContinueClick}>For help contact us at support@shuttr.ai</p>
        <button className="primary-button"  
          style={{ position: 'relative', width:'100%', maxWidth: 'none', marginTop: '16px'}} onClick={handleSaveZip}>Save to Zip</button>
          <button className="primary-button"  
          style={{ position: 'relative', width:'100%', maxWidth: 'none', marginTop: '16px'}} onClick={handleStartAgain}>Start again?</button>
        </div>)}
      </div>
      
    )
}

export default Portfolio;
