class ImageAttachment {
  constructor(uri,imageName,progress,state) {
    this.uri = uri;
    this.imageName = imageName;
    this.progress = progress;
    this.state = state;
  }
}

export default ImageAttachment;
